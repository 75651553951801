<template>
  <div class="page mt-8">
    <div v-if="!loaded">
      <Placeholder type="pundetail" />
    </div>
    <div v-if="loaded">
      <PunBreadcrumbs />
      <PunTitle />
      <div class="updateDate mb-5">
        <img src="@/assets/icons/Time.svg" />
        <div class="BodyM-Strong ml-2">
          {{ infoPUNtualeData.updateDate }}
        </div>
      </div>
      <div class="chartsBox mb-4">
        <PunChart
          class="mr-2"
          :date="infoPUNtualeData.todayChart.date"
          :copyDate="infoPUNtualeData.todayChart.copyDate"
          :ordinateUnitMeasurement="
            infoPUNtualeData.todayChart.ordinateUnitMeasurement
          "
          :series="infoPUNtualeData.todayChart.series"
          :categories="infoPUNtualeData.todayChart.categories"
          :yaxisMin="infoPUNtualeData.todayChart.ymin"
          :yaxisMax="infoPUNtualeData.todayChart.ymax"
          :hasTomorrowData="true"
        />
        <PunChart
          class="ml-2"
          :date="infoPUNtualeData.tomorrowChart.date"
          :copyDate="infoPUNtualeData.tomorrowChart.copyDate"
          :ordinateUnitMeasurement="
            infoPUNtualeData.tomorrowChart.ordinateUnitMeasurement
          "
          :series="infoPUNtualeData.tomorrowChart.series"
          :categories="infoPUNtualeData.tomorrowChart.categories"
          :yaxisMin="infoPUNtualeData.tomorrowChart.ymin || 0"
          :yaxisMax="infoPUNtualeData.tomorrowChart.ymax || 0"
          :hasTomorrowData="infoPUNtualeData.tomorrowChart.hasChartData"
          :errorCopy="infoPUNtualeData.tomorrowChart.errorCopy"
        />
      </div>

      <div class="legendBox mb-11">
        <img src="@assets/pun/lollipop.svg" />
        <div class="ml-3 LabelM">{{ infoPUNtualeData.copyLegenda }}</div>
      </div>
    </div>
    <ServiceUnavailableDialog
      :serviceUnavailableDialogOpen.sync="serviceUnavailableDialogOpen"
      :imageResponse="imageServiceError"
      :title="titleServiceError"
      :body="bodyServiceError"
      :cta1="cta1"
      :cta2="cta2"
      @retry="loadPunDetails"
      @returnToHome="returnToHome"
    />
    <ServiceUnavailableDialog
      :serviceUnavailableDialogOpen.sync="punErrorDialog"
      :imageResponse="imageServiceError"
      :title="titleServiceError"
      :body="bodyServiceError"
      :ctaExternal="ctaExternal"
      :cta1="cta1"
      :cta2="cta2"
      @toGME="toGME"
      @goHomeOrGoForm="returnToHome"
    />
  </div>
</template>

<script>
import PunTitle from '@/components/pun/PunTitle.vue'
import PunChart from '@/components/pun/PunChart.vue'
import PunBreadcrumbs from '@/components/pun/PunBreadcrumbs.vue'
import { mapActions, mapGetters } from 'vuex'
import ServiceUnavailableDialog from '../../components/global/ServiceUnavailableDialog.vue'
import Placeholder from '@components/global/Placeholder.vue'
import router from '../../router/router'
export default {
  name: 'PunDetail',
  components: {
    PunTitle,
    PunChart,
    PunBreadcrumbs,
    ServiceUnavailableDialog,
    Placeholder
  },
  data() {
    return {
      loaded: false,
      serviceUnavailableDialogOpen: false,
      imageServiceError: '',
      titleServiceError: '',
      bodyServiceError: '',
      cta1: '',
      cta2: '',
      ctaExternal: false,
      punErrorDialog: false
    }
  },
  mounted() {
    this.loadPunDetails()
  },
  computed: {
    ...mapGetters('supply', [
      'getInfoPUNtuale',
      'getDetailsData',
      'getCheckInfoPUNtuale'
    ]),
    infoPUNtualeData() {
      return this.getInfoPUNtuale
    }
  },
  methods: {
    ...mapActions('supply', ['retrieveInfoPUNtuale', 'dettaglioFornituraV2']),
    ...mapActions('analytics', ['trackTapEvent']),
    async loadPunDetails() {
      await this.checkDeeplink()
      if (!this.getCheckInfoPUNtuale) {
        router.push({ name: 'home' })
      }
      try {
        await this.retrieveInfoPUNtuale()
        if (this.infoPUNtualeData.errorData) {
          this.punErrorDialogOpen()
        } else {
          this.loaded = true
          this.trackTapEvent('trend_pun_viewed')
        }
      } catch (error) {
        this.openServiceUnavailableDialog()
      }
    },
    toGME() {
      window.open('https://www.mercatoelettrico.org/', '_blank')
    },
    returnToHome() {
      this.$router.push({ name: 'home' })
    },
    punErrorDialogOpen() {
      this.imageServiceError = 'KO-500'
      this.titleServiceError = 'Riprova più tardi'
      this.bodyServiceError =
        'Siamo spiacenti, ma per un errore temporaneo non è possibile visualizzare i dati del PUN Index GME orario. Riprova più tardi oppure visita il sito del GME.'
      this.cta1 = 'Torna alla homepage'
      this.cta2 = 'Vai al sito del GME'
      this.ctaExternal = true
      this.punErrorDialog = true
    },
    openServiceUnavailableDialog() {
      this.imageServiceError = 'KO-500'
      this.titleServiceError = 'Servizio temporaneamente non disponibile'
      this.bodyServiceError =
        'Il servizio è temporaneamente non disponibile. Riprova ora oppure in un secondo momento.'
      this.cta1 = 'Riprova ora'
      this.cta2 = 'Torna alla homepage'
      this.serviceUnavailableDialogOpen = true
    },
    async checkDeeplink() {
      try {
        let params = {
          supplyCode: this.$route.params.supplyCode,
          clientOwnerCode: this.$route.params.clientOwnerCode
        }
        await this.dettaglioFornituraV2(params)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
@media screen and (max-width: 768px) {
  .chartsBox {
    display: flex;
    flex-direction: column;
  }
}
.punDetails {
  width: 80%;
  margin: 0 auto;
}
.updateDate {
  display: flex;
  align-items: center;
}
.legendBox {
  margin: 0 auto;
  width: 360px;
  background-color: $neutri-neutral-10;
  padding: 8px 16px 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chartsBox {
  display: flex;
}
.apexcharts-xaxistooltip {
  background: $srg-blue !important;
  color: white !important;
}
</style>
<style lang="scss">
@import '@/styles/variables.scss';
.apexcharts-xaxistooltip,
.apexcharts-yaxistooltip {
  color: white !important;
  background: $srg-blue !important;
  border: 1px solid $srg-blue !important;
}
.apexcharts-xaxistooltip-bottom:before {
  border-bottom-color: $srg-blue !important;
}
.apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: $srg-blue !important;
}
.legendaSkeleton {
  display: flex;
  justify-content: center;
}
g.apexcharts-inner.apexcharts-graphical :nth-child(2).apexcharts-xcrosshairs {
  stroke: #12256a;
}
</style>

<template>
  <div class="receiveBill">
    <div class="">
      <v-text-field
        label="Presso"
        class="auth__input"
        v-model="paperInvoiceCustom.among"
        @change="updateStoredPaperInvoiceMethod"
      ></v-text-field>
    </div>
    <div class="">
      <v-autocomplete
        v-model="paperInvoiceCustom.city"
        :items="items.city"
        :search-input.sync="searchCity"
        @blur="v$.paperInvoiceCustom.city.$touch()"
        :error-messages="cityErrors"
        color="#12256A"
        item-text="comune"
        item-value="comune"
        label="Comune"
        @change="onCitySelected"
        :no-data-text="noDataText"
        return-object
        autocomplete="none"
      ></v-autocomplete>
    </div>
    <div class="">
      <v-text-field
        :disabled="!paperInvoiceCustom.city"
        v-model="paperInvoiceCustom.cap"
        placeholder="Cap"
        persistent-placeholder
      />
    </div>
    <v-row no-gutters class="mb-6">
      <v-col cols="8">
        <v-autocomplete
          v-model="paperInvoiceCustom.address"
          :items="items.address"
          :search-input.sync="searchAddress"
          :disabled="!paperInvoiceCustom.city || !paperInvoiceCustom.cap"
          @blur="v$.paperInvoiceCustom.address.$touch()"
          :error-messages="addressErrors"
          color="#12256A"
          item-text="via"
          item-value="via"
          label="Indirizzo"
          :no-data-text="noDataText"
          return-object
          class="pr-6"
          autocomplete="none"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="paperInvoiceCustom.number"
          :items="items.civicNumber"
          :search-input.sync="searchCivicNumber"
          :disabled="
            !paperInvoiceCustom.city ||
            !paperInvoiceCustom.cap ||
            !paperInvoiceCustom.address
          "
          @blur="v$.paperInvoiceCustom.number.$touch()"
          :error-messages="numberErrors"
          color="#12256A"
          item-text="civico"
          item-value="civico"
          label="n° civico"
          :no-data-text="noDataText"
          return-object
          autocomplete="none"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <div class="btn-custom" @click="editAddress">Modifica</div>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'receiveBill',
  data() {
    return {
      digitalInvoice: { type: 'digital' },
      paperInvoice: null,
      debounceTimeout: null,
      paperInvoiceCustom: {
        type: 'paper',
        address: '',
        number: '',
        city: '',
        cap: '',
        among: ''
      },
      searchAddress: '',
      searchCivicNumber: '',
      searchAmong: '',
      searchCity: '',
      searchCap: '',
      isLoading: {
        address: false,
        number: false,
        among: false,
        city: false,
        cap: false
      },
      items: { address: [], civicNumber: [], among: [], city: [], cap: [] },
      capList: [],
      noDataText: 'Inizia digitare per visualizzare i risultati'
    }
  },
  watch: {
    async searchCity(comune) {
      this.debounce(this.handleSearchCity, 200, comune)
    },
    async searchAddress(via) {
      this.debounce(this.handleSearchAddress, 200, via)
    },
    async searchCivicNumber(civico) {
      this.debounce(this.handleSearchCivicNumber, 100, civico)
    }
  },
  computed: {
    ...mapGetters('upsellingFiber', ['invoiceMethod']),

    cityErrors() {
      let errArray = []

      if (!this.v$.paperInvoiceCustom.city.$dirty) return errArray

      this.v$.paperInvoiceCustom.city.required.$invalid === true &&
        errArray.push('La citta è obbligatoria')

      return errArray
    },
    addressErrors() {
      let errArray = []

      if (!this.v$.paperInvoiceCustom.address.$dirty) return errArray

      this.v$.paperInvoiceCustom.address.required.$invalid === true &&
        errArray.push("L'indirizzo è obbligatorio")

      return errArray
    },
    numberErrors() {
      let errArray = []

      if (!this.v$.paperInvoiceCustom.number.$dirty) return errArray

      this.v$.paperInvoiceCustom.number.required.$invalid === true &&
        errArray.push('Il numero civico è obbligatorio')

      return errArray
    }
  },
  methods: {
    ...mapMutations('upsellingFiber', ['setInvoiceMethod']),
    ...mapActions('egon', ['getEgonComune', 'getEgonVia', 'getEgonCivico']),
    onCitySelected(city) {
      this.items.province = [city]
    },
    debounce(func, delay, ...args) {
      // Cancella il timeout precedente
      clearTimeout(this.debounceTimeout)
      // Imposta un nuovo timeout
      this.debounceTimeout = setTimeout(() => {
        func(...args)
      }, delay)
    },
    async handleSearchCity(comune) {
      let comuni = await this.getEgonComune(comune)
      this.items.city = comuni
    },
    async handleSearchAddress(via) {
      let vie = await this.getEgonVia({
        comuneEgon: this.paperInvoiceCustom.city.comuneEgon,
        via: via
      })
      this.items.address = vie
    },
    async handleSearchCivicNumber(civico) {
      let civici = await this.getEgonCivico({
        civico: civico,
        viaEgon: this.paperInvoiceCustom.address.viaEgon
      })
      this.items.civicNumber = civici
    },
    editAddress() {
      if (this.validatationHandler()) {
        const body = {
          sameAsSupplyAddress: false,
          type: this.paperInvoiceCustom.type,
          address: {
            label: this.paperInvoiceCustom.address.via,
            value: this.paperInvoiceCustom.address.viaEgon
          },
          cap: {
            label: this.paperInvoiceCustom.cap
          },
          city: {
            fullInfo: {
              DSXOBJCNL: this.paperInvoiceCustom.city.comune,
              DSXOBJDPT: this.paperInvoiceCustom.city.provincia,
              DSXOBJREG: this.paperInvoiceCustom.city.regione
            }
          },
          number: {
            label: this.paperInvoiceCustom.number.civico,
            value: Number(this.paperInvoiceCustom.number.civicoEgon),
            fullInfo: {
              NRPNUMCIV: {
                lValue: Number(this.paperInvoiceCustom.number.civico)
              },
              DSXESP: this.paperInvoiceCustom.number.esponente
            }
          }
        }
        this.setInvoiceMethod(body)
        this.$emit('updateAddress')
      }
    },
    updateStoredPaperInvoiceMethod() {
      this.setInvoiceMethod(this.paperInvoiceCustom)
    },

    validatationHandler() {
      // Check if invoiceMethod has a value
      let result = false
      if (this.invoiceMethod) {
        if (this.invoiceMethod.type === this.digitalInvoice.type) {
          result = true
        } else if (this.invoiceMethod.type === this.paperInvoiceCustom.type) {
          const paperI = this.paperInvoiceCustom
          // validate all required fields have a value
          result = !!(
            paperI.city &&
            paperI.cap &&
            paperI.address &&
            paperI.number
          )
          if (!result) {
            // Touch all paper invoice fields, so that they show the red validation messages
            this.v$.paperInvoiceCustom.city.$touch()
            this.v$.paperInvoiceCustom.cap.$touch()
            this.v$.paperInvoiceCustom.address.$touch()
            this.v$.paperInvoiceCustom.number.$touch()
          }
        }
      }
      return result
    }
  },
  validations: {
    paperInvoiceCustom: {
      city: { required },
      cap: { required },
      address: { required },
      number: { required }
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-custom {
  width: 242px;
  text-align: center;
  padding: 18px;
  color: black;
  border: 1px solid #ccd0e1;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>

import { axiosACN } from '@/js/axiosInstances'
import consts from '@/js/constants'
import { OVERALL_STATUS } from '@/js/constants'
import { capitalize } from '@/js/utils'
import { ENV_CONFIGS } from '@/js/configs.js'
import { getPromiseSingletone } from '../../js/service/promiseStatus'
import { get, set } from 'lodash'

let mockHomepageData = {
  status: 'OK',
  points: [
    {
      ownerClientCode: null,
      acuId: '6582a21605e5a568503a7fbf',
      ownerClientType: 'RES',
      firstname: 'Casea2',
      lastname: 'Test',
      businessName: 'Casea2 Test',
      pointCode: null,
      activationState: 'ACTIVE',
      supplyType: 'PHOTOVOLTAIC',
      fiberCoverage: null,
      fibercop: false,
      clientRole: 'I',
      isDigital: false,
      isPlacet: false,
      isShared: false,
      region: '',
      county: null,
      city: 'Verona',
      fraction: null,
      street: 'Via Locatelli, 5 5',
      egonStreetId: '',
      zipcode: '37122',
      streetnumber: null,
      egonStreetNumberExpId: null,
      egonStreetNumberId: '',
      endDate: null,
      mainRole: null,
      pod: 'IT001E444555',
      pdr: null,
      offerName: null,
      productId: null,
      dac: null,
      technologyType: null,
      connectionType: null,
      bandwidth: null,
      requestType: null,
      activationDate: null,
      creditPURL: null,
      notRegularPayments: 0,
      consumptionStatus: null,
      consumptionType: null,
      hasOngoingPaymentPlan: false,
      expiredInstallments: 0,
      photovoltaicData: {
        ownerClientCodeFV: '111118000000492',
        siteId: '100000420100000',
        installationStatus: 'ALARM',
        installationConnectionStartDate: '2022-12-20T00:00:00Z',
        installationPower: '5.52',
        installationType: 'PROSUMAGE',
        macroDevices: [
          {
            macroDeviceId: '100000420101000',
            macroDeviceType: 'inverter_system',
            microDevicesCount: 1,
            macroDeviceStatus: 'ALARM',
            startDate: '2022-12-20T00:00:00Z',
            endDate: null,
            maximumDeliverableEnergy: null
          },
          {
            macroDeviceId: '100000420102000',
            macroDeviceType: 'energy_meter_system',
            microDevicesCount: 1,
            macroDeviceStatus: 'ALARM',
            startDate: '2022-12-20T00:00:00Z',
            endDate: null,
            maximumDeliverableEnergy: null
          },
          {
            macroDeviceId: '100000420103000',
            macroDeviceType: 'storage_system',
            microDevicesCount: 2,
            macroDeviceStatus: 'ALARM',
            startDate: '2022-12-20T00:00:00Z',
            endDate: null,
            maximumDeliverableEnergy: null
          },
          {
            macroDeviceId: '100000420103000',
            macroDeviceType: 'storage_system',
            microDevicesCount: 2,
            macroDeviceStatus: 'ALARM',
            startDate: '2022-12-20T00:00:00Z',
            endDate: null,
            maximumDeliverableEnergy: null
          }
        ]
      }
    }
  ]
}

// Checks for account properties to then store them into the account store module to be used throughout the app
const checkAccountProperties = (supplies) => {
  let accountPops = {
    hasGasActive: false,
    hasGasActivating: false,
    hasLightActive: false,
    hasLightActivating: false,
    hasFiberActive: false,
    hasFiberActivating: false,
    hasAtLeast1Placet: false,
    hasAtLeast1Digital: false,
    hasAtLeast1NonDigital: false,
    isFiberEligible: false,
    fiberEligibleSupplies: [],
    hasLoyaltyEnrollment: false,
    isLoyaltyEligible: false,
    isOnlyPhotovoltaic: false,
    hasPhotovoltaicActive: false,
    hasPhotovoltaicActivating: false
  }

  let suppliesForAddress = {}

  for (let sup of supplies) {
    accountPops.hasPhotovoltaicActive =
      (sup.serviceType === consts.SERVICE_TYPE.PHOTOVOLTAIC &&
        sup.supplyState === consts.SUPPLY_STATUS.ACTIVE) ||
      accountPops.hasPhotovoltaicActive

    accountPops.hasPhotovoltaicActivating =
      (sup.serviceType === consts.SERVICE_TYPE.PHOTOVOLTAIC &&
        sup.supplyState === consts.SUPPLY_STATUS.ACTIVATING) ||
      accountPops.hasPhotovoltaicActivating

    accountPops.hasGasActive =
      (sup.serviceType === consts.SERVICE_TYPE.GAS &&
        sup.supplyState === consts.SUPPLY_STATUS.ACTIVE) ||
      accountPops.hasGasActive
    accountPops.hasGasActivating =
      (sup.serviceType === consts.SERVICE_TYPE.GAS &&
        sup.supplyState === consts.SUPPLY_STATUS.ACTIVATING) ||
      accountPops.hasGasActivating

    accountPops.hasLightActive =
      (sup.serviceType === consts.SERVICE_TYPE.ELE &&
        sup.supplyState === consts.SUPPLY_STATUS.ACTIVE) ||
      accountPops.hasLightActive
    accountPops.hasLightActivating =
      (sup.serviceType === consts.SERVICE_TYPE.ELE &&
        sup.supplyState === consts.SUPPLY_STATUS.ACTIVATING) ||
      accountPops.hasLightActivating

    accountPops.hasFiberActive =
      (sup.serviceType === consts.SERVICE_TYPE.FIBER &&
        sup.supplyState === consts.SUPPLY_STATUS.ACTIVE) ||
      accountPops.hasFiberActive
    accountPops.hasFiberActivating =
      (sup.serviceType === consts.SERVICE_TYPE.FIBER &&
        sup.supplyState === consts.SUPPLY_STATUS.ACTIVATING) ||
      accountPops.hasFiberActivating

    accountPops.hasAtLeast1Placet =
      sup.isPlacet || accountPops.hasAtLeast1Placet
    accountPops.hasAtLeast1Digital =
      sup.isDigital || accountPops.hasAtLeast1Digital
    accountPops.hasAtLeast1NonDigital =
      sup.isDigital === false || accountPops.hasAtLeast1NonDigital

    accountPops.isFiberEligible =
      sup.fiberCoverage || accountPops.isFiberEligible

    if (
      sup.fiberCoverage &&
      sup.serviceType !== consts.SERVICE_TYPE.GAS.FIBER
    ) {
      accountPops.fiberEligibleSupplies.push(sup)
    }

    const address = `${sup.county}_${sup.city}_${sup.street}_${
      sup.streetNumber || ''
    }_${sup.zipCode}`
    suppliesForAddress[address] = suppliesForAddress[address] || {}
    suppliesForAddress[address][sup.serviceType] =
      sup.supplyState !== consts.SUPPLY_STATUS.CLOSED
  }

  const addressId = (sup) =>
    `${sup.egonRegion || ''}_${sup.county}_${sup.city}_${sup.egonLocality}_${
      sup.zipcode
    }_${sup.egonStreet}_${sup.egonStreetNumber}_${
      sup.egonStreetNumberLetter
    }`.toLowerCase()

  // Fiber eligibility: only if there is at least 1 address eligible and on that address there are no (active) fiber contract
  accountPops.fiberEligibleSupplies = accountPops.fiberEligibleSupplies.filter(
    (eligibleSupply) => {
      let addressHasFiberAlready = supplies.filter((sup) => {
        if (!sup.egonStreet) return false
        return (
          addressId(eligibleSupply) === addressId(sup) &&
          sup.serviceType === consts.SERVICE_TYPE.FIBER &&
          sup.supplyState !== consts.SUPPLY_STATUS.CLOSED &&
          sup.supplyState !== consts.SUPPLY_STATUS.REJECTED
        )
      })

      return addressHasFiberAlready.length === 0
    }
  )
  // ESISTE GIA MAINCLIENT.ISONLYFV
  accountPops.isOnlyPhotovoltaic =
    supplies.filter((sup) => {
      sup.serviceType === consts.SERVICE_TYPE.PHOTOVOLTAIC
    }).length === supplies.length

  accountPops.isFiberEligible = accountPops.fiberEligibleSupplies.length > 0

  return accountPops
}

const sendAfterthoughtRequest = (
  commit,
  rootGetters,
  { clientDetail, serviceType, contract, motivazioneRipensamento }
) => {
  let urlFiber = ''
  let paramsFiber = {}
  let urlCommodity = ''
  let paramsCommodity = {}

  const userName = rootGetters['session/username']
  let currentDate = new Date()
  if (serviceType === consts.SERVICE_TYPE.FIBER) {
    currentDate = `${currentDate.getDate()}/${
      currentDate.getMonth() + 1 < 10
        ? '0' + (currentDate.getMonth() + 1)
        : currentDate.getMonth() + 1
    }/${currentDate.getFullYear()}`
    urlFiber = '/commodity/creazioneInterazione'
    paramsFiber = {
      cognome: clientDetail.surname,
      nome: clientDetail.name,
      email: clientDetail.contactMail,
      codiceCliente: clientDetail.clientCode,
      login: userName,
      fieldsCommodity: {
        commodity: 'ripensamentoFibra',
        codiceProposta: contract.cdPropostaContrattoFibra,
        data: currentDate
      }
    }

    return axiosACN
      .post(urlFiber, paramsFiber, {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      })
      .then((res) => {
        // Manage business logic errors (coming with status 200 OK + errorcode)
        if (res.status >= 400 || res.data.errorCode) {
          // No bus logic errors expected
          throw Error('UNKNOWN_ERROR')
        }

        return res.status
      })
      .catch((err) => {
        if (err.statusCode && err.statusCode >= 400) {
          throw Error('SERVER_ERROR')
        } else if (err.message) {
          throw err
        }
      })
  } else {
    // if (supply.supplyState === consts.SUPPLY_STATUS.ACTIVE) {
    // If active contact Mecoms

    currentDate = `${
      currentDate.getDate() + 1 < 10
        ? '0' + currentDate.getDate()
        : currentDate.getDate()
    }/${
      currentDate.getMonth() + 1 < 10
        ? '0' + (currentDate.getMonth() + 1)
        : currentDate.getMonth() + 1
    }/${currentDate.getFullYear()}`
    urlCommodity = '/commodity/v2/registraRipensamento'
    paramsCommodity = {
      username: userName,
      codiceProposta: contract.cdPropostaContratto || contract.codProposta,
      codiceCliente: clientDetail.clientCode,
      dataRipensamento: currentDate,
      nome: clientDetail.name,
      cognome: clientDetail.surnmae,
      motivazioneRipensamento: motivazioneRipensamento,
      email: clientDetail.contactMail
    }
    return axiosACN
      .post(urlCommodity, paramsCommodity, {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      })
      .then((res) => {
        // Manage business logic errors (coming with status 200 OK + errorcode)
        if (res.data.errorCode) {
          // No bus logic errors expected
          throw Error('UNKNOWN_ERROR')
        }

        return res
      })
      .catch((err) => {
        if (err.statusCode && err.statusCode >= 400) {
          throw Error('SERVER_ERROR')
        } else if (err.message) {
          throw err
        }
      })
  }
}

const sendReading = (rootGetters, params) => {
  if (!rootGetters['session/username']) return
  const url =
    params.commodity === 'Ele'
      ? '/commodity/invioAutoletturaEle'
      : '/commodity/invioAutoletturaGas'
  return axiosACN
    .post(
      url,
      {
        codicePR: params.supplyCode,
        codiceCliente: params.clientOwnerCode,
        codiceUtenza: params.supplyCode.replace('PR', '').replace('pr', ''),
        lettura1: params.readings.f1 || '',
        lettura2: params.readings.f2 || '',
        lettura3: params.readings.f3 || '',
        canale: 'WEB'
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      // Manage business logic errors (coming with status 200 OK + errorcode)
      if (res.data.errorCode || res.data.statusCode >= 400) {
        // No bus logic errors expected
        if (
          res.data.errorCode === 'MET_LET_15' ||
          res.data.errorCode === 'MET_LET_17'
        ) {
          return {
            errorCode: 'WRONG_VALUES_CALLCENTER',
            raw: {
              errorCode: res.data.errorCode,
              errorDescription: res.data.errorDescription
            }
          }
        } else if (res.data.errorCode === 'GENERICO') {
          return {
            errorCode: 'GENERIC',
            raw: {
              errorCode: res.data.errorCode,
              errorDescription: res.data.errorDescription
            }
          }
        } else {
          throw Error('UNKNOWN_ERROR')
        }
      }

      return res
    })
    .catch((err) => {
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

const getPurlForCredit = async (state, token, clientCode) => {
  if (state.supplyCredit[clientCode]) {
    return Promise.resolve(state.supplyCredit[clientCode])
  } else {
    return axiosACN
      .post(
        '/sorgeniadataclient/retrievePurl',
        {
          codiceCliente: clientCode
        },
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      .then((res) => {
        // Manage business logic errors (coming with status 200 OK + errorcode)
        if (res.data.errorCode) {
          // No bus logic errors expected
          throw Error('UNKNOWN_ERROR')
        }

        state.supplyCredit[clientCode] = res.data.listaPurl
        return res.data.listaPurl
      })
  }
}

const retrieveSuppliesAlerts = async (state, commit, dispatch, rootGetters) => {
  let alerts = await dispatch('alerts/initSuppliesAlerts', false, {
    root: true
  })
  let allPoints = {
    payment: alerts?.ele?.points
      .concat(alerts?.gas?.points)
      .concat(alerts?.fiber?.points),
    consumption: alerts?.ele?.consumptionPoints.concat(alerts?.gas?.points),
    timeline: alerts?.ele?.timelinePoints
      .concat(alerts?.gas?.timelinePoints)
      .concat(alerts?.fiber?.timelinePoints),
    photovoltaic: alerts.photovoltaic.points
  }
  return allPoints
}

const retrievePaymentStatus = (commit, rootGetters, params) => {
  return axiosACN
    .post(
      '/bollette/V3/retrievePaymentStatus',
      {
        listaClienti: params.clientCode
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      commit('setPaymentStatus', res.data.listaClienti)
      return res
    })
}

const retrieveSupplyList = (
  state,
  commit,
  dispatch,
  rootGetters,
  alertsP,
  alertC,
  alertT
) => {
  if (!rootGetters['session/username']) return

  if (
    !window.retrieveSupplyListPromise ||
    !getPromiseSingletone(window.retrieveSupplyListPromise)
  ) {
    window.retrieveSupplyListPromise = new Promise((resolve, reject) => {
      axiosACN
        .post(
          '/sorgeniadataclient/V6/retrievehomepagedata',
          {
            username: rootGetters['session/username']
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then(async (res) => {
          // Manage business logic errors (coming with status 200 OK + errorcode)
          if (res.data.errorCode) {
            // No bus logic errors expected
            throw Error('UNKNOWN_ERROR')
          }

          const getSpeedFromTipology = (point) => {
            if (point.tipologia === 'EVDSL') return 200
            else if (point.tipologia === 'VDSL') return 100
            else return 1000
          }

          let supplies = []
          let payStaus = alertsP
          let consumptionStatus = alertC
          let timelineStatus = alertT
          const installationStatus = [
            OVERALL_STATUS.ALARM,
            OVERALL_STATUS.NOT_COMMUNICATING,
            OVERALL_STATUS.UNKNOWN
          ]
          for (let point of res.data.points) {
            // for (let point of mockHomepageData.points) {
            let supply = {
              clientOwnerCode: point.ownerClientCode,
              acuId: point.acuId,
              clientOwnerType: point.ownerClientType,
              firstName: capitalize(point.firstname),
              lastName: capitalize(point.lastname),
              businessName: point.businessName,

              supplyCode: point.pointCode || '',
              supplyState: point.activationState,
              serviceType: point.supplyType,
              product: point.productId || '',
              offerName: point.offerName || '',
              pdr: point.pdr || '',
              pod: point.pod,
              role: point.clientRole,
              mainRole: point.mainRole || '',
              fiberCoverage:
                point.fiberCoverage === 'FW' || point.fiberCoverage === 'OF',
              fiberCoverageStatus: point.fiberCoverage,
              fiberType:
                point.fiberCoverage === 'FW'
                  ? point.fibercop
                    ? 'FC'
                    : 'FW'
                  : 'OF',
              isDigital: !!point.isDigital,
              isPlacet: !!point.isPlacet,
              isShared: !!point.isDigital,

              county: point.county || '',
              city: point.city || '',
              street: point.street || '',
              streetNumber: point.streetnumber || '',
              zipCode: point.zipcode,

              egonRegion: point.region || '',
              egonLocality: point.fraction || '',
              egonStreet: point.egonStreetId || '',
              egonStreetNumber: point.egonStreetNumberId || '',
              egonStreetNumberLetter: point.egonStreetNumberExpId || '',
              dac: point.dac || '',
              typology: point.technologyType || '',
              connectionType: point.connectionType || '',
              speed: point.bandwidth || '',
              speedVal: point.bandwidth
                ? point.bandwidth.split('/')[0]
                : getSpeedFromTipology(point),
              typeRequest: point.requestType || '',
              activationDate: point.activationDate || '',
              endDate: point.endDate || '', // data di cessazione
              credits: point.creditPURL,
              notRegularPayments: point.notRegularPayments || '', // indicatore numerico di pagamenti regolari: valore 0 in caso di pagamenti regolari, 1 altrimenti
              consumptionStatus: point.consumptionStatus || '', // Stato consumi. Valori possibili: unavailable, regular, overconsumption
              consumptionType: point.consumptionType || '', // Tipo di consumo. Valori possibili: measured, estimated
              hasOngoingPaymentPlan: point.hasOngoingPaymentPlan, // Flag per la presenza di piani di rientro aperti. "true" se presente almeno un piano di rientro aperto, "false" altriment
              expiredInstallments: point.expiredInstallments, // Numero di rate scadute per cliente + punto
              photovoltaicData: point.photovoltaicData || undefined
            }

            if (payStaus && payStaus.length > 0) {
              let status = payStaus.filter(
                (p) => p.prCode === point.pointCode
              )[0]
              supply.hasRegularStatus =
                !status ||
                status.paymentStatus === 'REGULAR' ||
                status.paymentStatus === 'VERIFYING'
              supply.paymentStatus = supply.hasRegularStatus
                ? 'Regolari'
                : 'Non regolari'
              supply.nExpiredBills = status && (status.nFattureScadute || null)
              supply.hasPaymentInfo = true
              if (supply.serviceType === consts.SERVICE_TYPE.GAS) {
                supply.isUnavailable = !status?.dataUltimaBolletta || false
              }
            }
            if (consumptionStatus && consumptionStatus.length > 0) {
              let status = consumptionStatus.filter(
                (p) => p.prCode === point.pointCode
              )[0]
              if (status) {
                supply.hasAbnormalConsumption =
                  status?.statusConsumi === 'Consumo superiore alla media' ||
                  false
                supply.isEstimate = status?.consumptionType === 'ESTIMATED'
                if (supply.serviceType === consts.SERVICE_TYPE.ELE) {
                  supply.isUnavailable =
                    status.statusConsumi === 'Non disponibile'
                }
              } else {
                supply.hasAbnormalConsumption = false
                supply.isEstimate = false
                if (supply.serviceType === consts.SERVICE_TYPE.ELE) {
                  supply.isUnavailable = false
                }
              }
            }
            if (timelineStatus && timelineStatus.length > 0) {
              let status = timelineStatus.filter(
                (p) => p.codicePunto === point.pointCode
              )[0]
              if (status) {
                supply.activationDate = status.dataAttivazione || ''
                supply.estimateDate =
                  status.dataPresuntaAttivazione ||
                  status.dataPrevistaAttivazione ||
                  ''
                supply.typeRequest = status.tipoRichiesta || ''
              } else {
                supply.activationDate = ''
                supply.estimateDate = ''
              }
            }
            if (
              supply.photovoltaicData &&
              supply.photovoltaicData.installationStatus
            ) {
              supply.isRegularPhotovoltaic = !installationStatus.includes(
                supply.photovoltaicData.installationStatus
              )
              supply.isOffline =
                supply.photovoltaicData.installationStatus ===
                OVERALL_STATUS.NOT_COMMUNICATING
            }
            supply.hasCredit = !!point.creditPURL

            supplies.push(supply)
          }
          commit('setSupplies', {
            supplies: supplies
          })

          const props = checkAccountProperties(supplies)
          commit('account/setAccountProperties', props, { root: true })

          if (!ENV_CONFIGS.ENABLE_ADMIN_LOGIN) {
            dispatch('analytics/setupTracking', false, { root: true })
          }

          resolve(supplies)
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    })
  }
  return window.retrieveSupplyListPromise
}

async function retrieveSupplyDetail(getters, commit, rootGetters, params) {
  try {
    let dataSupply = await axiosACN.post(
      '/fornitura/V2/dettaglioFornitura',
      {
        // codicePunto: 'PR08924366',
        codicePunto: params.supplyCode,
        codiceCliente: params.clientOwnerCode
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    if (!dataSupply.data.codicePunto) {
      throw Error('UNKNOWN_ERROR')
    }
    let supplyDetails = dataSupply.data
    commit('FETCH_SUPPLY_DETAIL', {
      supply: supplyDetails
    })
    commit('setParentalControlStatus', supplyDetails.parentalControlStatus)
    let dataBill = await axiosACN.post(
      '/bollette/V3/recuperoBollette',
      {
        // codicePunto: 'PR08924366',
        codiceCliente: supplyDetails.codiceClienteIntestatario,
        codicePunto: params.supplyCode,
        startDate: dateFormatTwoVal(get24MonthsAgoDate()),
        endDate: dateFormatTwoVal(new Date())
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      },
      commit('setDetailsData', dataSupply.data)
    )
    commit('FETCH_BILLS_DETAIL', {
      codicePunto: params.supplyCode,
      bill: dataBill.data
    })

    let dataConsumtion = await axiosACN.post(
      '/fornitura/retrieveDettaglioFornituraConsumi',
      {
        // codicePunto: 'PR5273610',
        codicePunto: params.supplyCode,
        startDate: dateFormatThreeVal(get24MonthsAgoDate()),
        endDate: dateFormatThreeVal(new Date()),
        username: rootGetters['session/username']
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    commit('FETCH_CONSUMPTION_DETAIL', {
      codicePunto: params.supplyCode,
      consumption: dataConsumtion.data
    })

    const dataSupplyServiceType = supplyDetails.servizio.toLowerCase()
    if (dataSupplyServiceType !== 'fiber') {
      let endPoint =
        dataSupplyServiceType === 'gas'
          ? 'V2/verificaAutoLetturaGas'
          : 'verificaAutoLetturaEle'
      let dataSelfReading = await axiosACN.post(
        '/fornitura/' + endPoint,
        {
          // codicePunto: 'PR5273610',
          codicePR: params.supplyCode,
          codiceCliente: supplyDetails.codiceClienteIntestatario,
          codiceUtenza: params.supplyCode.replace('PR', '').replace('pr', ''),
          canale: 'WEB'
        },
        {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        }
      )

      if (dataSelfReading.data.status === 'OK') {
        let selfReadingStatus = 'ALLOWED' // 'ALLOWED' | 'TOO_EARLY' | 'THROUGH_CALLCENTER' (ele only) | 'NOT_NEEDED' (ele only)
        if (
          dataSelfReading.data.errorDescription &&
          dataSelfReading.data.errorDescription !== ''
        ) {
          selfReadingStatus = 'NOT_AVAILABLE'
        }
        if (
          dataSelfReading.data.errorCode === 'GENERIC' ||
          dataSelfReading.data.errorDescription === 'GENERIC'
        ) {
          selfReadingStatus = 'TOO_EARLY'
        } else if (dataSupplyServiceType === 'ele') {
          if (parseInt(supplyDetails.potenza) > 15) {
            selfReadingStatus = 'THROUGH_CALLCENTER'
          }
          if (
            dataSelfReading.data.errorCode === 'MISURATORE_2G' ||
            dataSelfReading.data.errorDescription === 'MISURATORE_2G'
          ) {
            selfReadingStatus = 'NOT_NEEDED'
          }
        } else if (dataSupplyServiceType === 'gas') {
          if (dataSelfReading.data?.anagraficaAcquisita?.tipoProfilo === 'C') {
            selfReadingStatus = 'NOT_NEEDED'
          }
          if (
            dataSelfReading.data.errorDescription ===
            'Il misuratore è di tipo a consumo'
          ) {
            selfReadingStatus = 'NOT_NEEDED'
          }
        }

        dataSelfReading.data.is2G =
          dataSelfReading.data.errorCode === 'MISURATORE_2G' ||
          dataSelfReading.data.errorDescription === 'MISURATORE_2G'
        dataSelfReading.data.availabilityStatus = selfReadingStatus

        commit('FETCH_SELFREADING_DETAIL', {
          codicePunto: params.supplyCode,
          selfReading: dataSelfReading.data
        })
      } else {
        console.error('Impossible to retrieve data SelfReading supply')
        // throw Error('UNKNOWN_ERROR')
      }
    }

    let dataMdp = await axiosACN.post(
      '/fornitura/retrieveDettaglioFornituraMdp',
      {
        // codicePunto: 'PR5273610',
        codicePunto: params.supplyCode,
        codiceCliente: supplyDetails.codiceClienteIntestatario
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    if (dataMdp.data.status === 'OK') {
      commit('FETCH_MDP_DETAIL', {
        codicePunto: params.supplyCode,
        mdp: dataMdp.data
      })
    } else {
      console.error('Impossible to retrieve supply MDP')
      // throw Error('UNKNOWN_ERROR')
    }

    return getters.supplyDetail[params.supplyCode]
  } catch (err) {
    if (err.statusCode && err.statusCode >= 400) {
      throw Error('SERVER_ERROR')
    } else if (err.message) {
      throw err
    }
  }
}

async function retrieveSupplyBills(rootGetters, params) {
  try {
    let dataBill = await axiosACN.post(
      '/bollette/V3/recuperoBollette',
      {
        codiceCliente: params.clientOwnerCode,
        codicePunto: params.supplyCode,
        startDate: dateFormatTwoVal(get24MonthsAgoDate()),
        endDate: dateFormatTwoVal(new Date())
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )

    return dataBill.data.listBollette
  } catch (err) {
    if (err.statusCode && err.statusCode >= 400) {
      throw Error('SERVER_ERROR')
    } else if (err.message) {
      throw err
    }
  }
}

const retrieveCurveConsumi = (commit, rootGetters, params) => {
  return axiosACN
    .post(
      '/fornitura/retrieveCurveConsumi',
      {
        codiceCliente: params.clientOwnerCode,
        codicePunto: params.supplyCode,
        startDate: params.startDate,
        endDate: params.endDate,
        frazione: params.frazione
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      if (res.data.errorCode) {
        throw Error('UNKNOWN_ERROR')
      }
      let curve = []
      for (let curva of res.data.curve) {
        // mockCreateCurve(params)
        const c = {}
        c.startDate = curva.dataCompetenzaStart
        c.endDate = curva.dataCompetenzaEnd
        c.curveconsumo =
          curva[Object.keys(curva).find((c) => c.includes('curveconsumo'))]
        if ('fascia0' in curva) {
          c.curveconsumo = curva.fascia0
          c.fascia0 = curva.fascia0
        } else if (
          Object.keys(curva).findIndex((c) => c.includes('fascia')) >= 0
        ) {
          c.fascia1 = curva.fascia1
          c.fascia2 = curva.fascia2
          c.fascia3 = curva.fascia3
        }
        if (
          Object.keys(curva).findIndex((c) => c.includes('piccopotenza')) >= 0
        ) {
          c.piccopotenza =
            curva[Object.keys(curva).find((c) => c.includes('piccopotenza'))]
        }
        curve.push(c)
      }
      curve.sort((a, b) => (a.startDate > b.startDate ? 1 : -1))

      commit('FETCH_CURVE_CONSUMI', {
        curveConsumi: curve
      })

      return {
        frazione: params.frazione,
        curve: curve,
        total: res.data.totaleCurveConsumo
      }
    })
    .catch((err) => {
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

const dateFormatTwoVal = (value) => {
  function pad(s) {
    return s < 10 ? '0' + s : s
  }

  var d = new Date(value)
  return [pad(d.getMonth() + 1), d.getFullYear()].join('/')
}

const dateFormatThreeVal = (value) => {
  function pad(s) {
    return s < 10 ? '0' + s : s
  }

  var d = new Date(value)
  return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
}

const get24MonthsAgoDate = () => {
  const d = new Date()
  d.setMonth(d.getMonth() - 24)

  return d
}

const retrieveTokenBidgely = (rootGetters, data) => {
  return axiosACN
    .post(
      '/extlogin/validate',
      {
        codiceCliente: data.clientOwnerCode,
        pod: data.pod
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      if (res.data.error) {
        throw Error('UNKNOWN_ERROR')
      }
      return res.data
    })
    .catch((err) => {
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    supplies: null,
    supplyDetail: null,
    status: 'pending',
    clientsWithSupplies: null,
    supplyCode: null,
    curveConsumi: null,
    supplyCredit: [],
    paymentStatus: [],
    cadastralData: {},
    parentalControlStatus: null,
    detailsData: null,
    punDetails: null,
    infoPUNtuale: null,
    checkInfoPUNtuale: null,
    checkParentalControlDeeplink: null
  },
  getters: {
    supplies(state) {
      return state.supplies
    },
    supplyType(state) {
      const supply = []

      state.supplies &&
        state.supplies.map &&
        state.supplies.map((item) => {
          if (
            (item.serviceType === consts.SERVICE_TYPE.ELE ||
              item.serviceType === consts.SERVICE_TYPE.GAS) &&
            !supply.includes(item.serviceType)
          ) {
            supply.push(item.serviceType)
          }
        })
      return supply.length > 1 ? 'all' : supply[0]
    },
    supplyDetail(state) {
      return state.supplyDetail
    },
    status: (state) => state.status,
    clientsWithSupplies(state) {
      return state.clientsWithSupplies
    },
    curveConsumi(state) {
      return state.curveConsumi
    },
    paymentStatus(state) {
      return state.paymentStatus
    },
    isNotAllPlacet(state) {
      if (state.supplies) {
        return state.supplies.filter((sup) => !sup.isPlacet).length > 0
      } else {
        return false
      }
    },
    cadastralData(state) {
      return state.cadastralData
    },
    getParentalControlStatus(state) {
      return state.parentalControlStatus
    },
    getPunDetails(state) {
      return state.punDetails
    },
    getDetailsData(state) {
      return state.detailsData
    },
    getInfoPUNtuale(state) {
      return state.infoPUNtuale
    },
    getCheckInfoPUNtuale(state) {
      return state.checkInfoPUNtuale
    },
    getCheckParentalControlDeeplink(state) {
      return state.checkParentalControlDeeplink
    }
  },
  mutations: {
    resetState(state) {
      delete window.retrieveSupplyListPromise
      state.supplies = null
      state.supplyDetail = null
      state.status = 'pending'
      state.clientsWithSupplies = null
      state.supplyCode = null
      state.curveConsumi = null
      state.supplyCredit = {}
      state.paymentStatus = []
    },
    setSupplies(state, { supplies }) {
      state.supplies = supplies
    },
    setSupplyCode(state, code) {
      state.supplyCode = code
    },
    resetSupplyDetailByCode(state, codicePunto) {
      if (state.supplyDetail) state.supplyDetail[codicePunto] = null
    },
    setClientsWithSupplies(state, data) {
      state.clientsWithSupplies = data
    },
    FETCH_BILLS_DETAIL(state, data) {
      state.supplyDetail[data.codicePunto].bills =
        data.bill && data.bill.listBollette
          ? data.bill.listBollette.reverse()
          : []
    },
    FETCH_CONSUMPTION_DETAIL(state, data) {
      state.supplyDetail[data.codicePunto].consumption =
        data.consumption && data.consumption.consumi.list
          ? data.consumption.consumi.list.sort(function (a, b) {
              return a.dataCompetenzaStart.localeCompare(b.dataCompetenzaStart)
            })
          : []
    },
    FETCH_SELFREADING_DETAIL(state, data) {
      state.supplyDetail[data.codicePunto].selfReading = data.selfReading
    },
    FETCH_MDP_DETAIL(state, data) {
      state.supplyDetail[data.codicePunto].mdp = data.mdp.points
    },
    FETCH_CADASTRAL_DETAIL(state, data) {
      state.supplyDetail[data.codicePunto].cadastral = data.cadastral
    },
    cadastraAlertDisplayed(state, codicePunto) {
      state.supplyDetail[codicePunto].cadastral['displayed'] = true
    },
    setParentalControlStatus(state, parentalControlStatus) {
      state.parentalControlStatus = parentalControlStatus
        ? parentalControlStatus
        : 'UNKNOWN'
      // state.parentalControlStatus = 'ACTIVE'
    },
    FETCH_SUPPLY_DETAIL(state, data) {
      const supplyDetail = {
        supplyState: data.supply.statoFornitura,
        firstName: capitalize(data.supply.nome),
        lastName: capitalize(data.supply.cognome),
        businessName: data.supply.ragioneSociale,
        address: data.supply.STREET,
        city: data.supply.CITY,
        zipCode: data.supply.ZIPCODE,
        cfp: data.supply.cfp,
        serviceType: data.supply.servizio,
        clientOwnerType: data.supply.tipoClienteIntestatario,
        clientOwnerCode: data.supply.codiceClienteIntestatario,
        supplyCode: data.supply.codicePunto,
        activationDate: data.supply.dataAttivazione,
        dataCessazione: data.supply.dataCessazione,
        product: data.supply.prodotto,
        offerName: data.supply.nomeOfferta,
        power: data.supply.potenza,
        pdr: data.supply.PDR,
        pod: data.supply.POD,
        billingType: data.supply.tipologiaFatturazione,
        bills: [],
        consumption: [],
        selfReading: null,
        mdp: [],
        cadastral: {},
        migrationCode:
          data.supply.codiceMigrazione === 'null'
            ? null
            : data.supply.codiceMigrazione,
        billingFrequency: data.supply.frequenzaFatturazione || '',
        //paramentro che verà aggiunto con il rilascio
        parentalControlStatus: data.supply.parentalControlStatus || null
      }
      // Init supplyDetail object if needed
      state.supplyDetail = {
        ...state.supplyDetail,
        [data.supply.codicePunto]: supplyDetail
      }

      // state.supplyDetail[data.supply.codicePunto] = supplyDetail

      state.status = 'success'
    },
    FETCH_CURVE_CONSUMI(state, curve) {
      state.curveConsumi = curve
    },
    updateStatus(state, status) {
      state.status = status
    },
    setPaymentStatus(state, paymentStatus) {
      state.paymentStatus = paymentStatus
    },
    seCadastralData(state, cadastral) {
      state.cadastral = cadastral
    },
    setPunDetails(state, punDetails) {
      state.punDetails = punDetails
    },
    setDetailsData(state, detailsData) {
      state.detailsData = detailsData
    },
    setInfoPUNtuale(state, infoPUNtuale) {
      state.infoPUNtuale = infoPUNtuale
    },
    setCheckInfoPUNtuale(state, checkInfoPUNtuale) {
      state.checkInfoPUNtuale = checkInfoPUNtuale
    },
    setCheckParentalControlDeeplink(state, checkParentalControlDeeplink) {
      state.checkParentalControlDeeplink = checkParentalControlDeeplink
    }
  },
  actions: {
    async dettaglioFornituraV2({ commit, rootGetters }, params) {
      try {
        let response = await axiosACN.post(
          '/fornitura/V2/dettaglioFornitura',
          {
            // codicePunto: 'PR08924366',
            codicePunto: params.supplyCode,
            codiceCliente: params.clientOwnerCode
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        let supplyDetails = response.data
        commit('FETCH_SUPPLY_DETAIL', {
          supply: supplyDetails
        })
        commit('setParentalControlStatus', supplyDetails.parentalControlStatus)
        commit('setCheckInfoPUNtuale', supplyDetails.infoPUN ? true : false)
        commit('setCheckParentalControlDeeplink', response.data ? true : false)
      } catch (err) {
        if (err.response.data.errorCode === '1094') {
          throw Error('USER_NOT_AUTHORIZED')
        } else {
          console.error('error in sendParentalControlRequest', err)
          throw err
        }
      }
    },
    async getSupplies({ state, commit, dispatch, rootGetters }) {
      if (state.supplies) {
        return Promise.resolve(state.supplies)
      } else {
        const alerts = await retrieveSuppliesAlerts(
          state,
          commit,
          dispatch,
          rootGetters
        )
        const supplies = await retrieveSupplyList(
          state,
          commit,
          dispatch,
          rootGetters,
          alerts.payment,
          alerts.consumption,
          alerts.timeline
        )
        commit('setSupplies', {
          supplies: supplies
        })

        return supplies
      }
    },
    async getDetail({ state, getters, commit, rootGetters }, params) {
      commit('setSupplyCode', params.supplyCode)
      if (state.supplyDetail && state.supplyDetail[params.supplyCode]) {
        return Promise.resolve(state.supplyDetail[params.supplyCode])
      } else {
        const details = await retrieveSupplyDetail(
          getters,
          commit,
          rootGetters,
          params
        )
        return details
      }
    },
    async retrieveParentalControlStatus(
      { state, getters, commit, rootGetters },
      params
    ) {
      commit('setSupplyCode', params.supplyCode)
      const details = await retrieveSupplyDetail(
        getters,
        commit,
        rootGetters,
        params
      )
      return details
    },
    getCurveConsumi({ state, commit, rootGetters }, params) {
      const curveConsumi = retrieveCurveConsumi(commit, rootGetters, params)
      return curveConsumi
    },
    getSupplyBills({ state, commit, rootGetters }, params) {
      if (
        state.supplyDetail &&
        state.supplyDetail[params.supplyCode] &&
        state.supplyDetail[params.supplyCode].bills
      ) {
        return Promise.resolve(state.supplyDetail[params.supplyCode].bills)
      } else {
        const bill = retrieveSupplyBills(rootGetters, params)
        return bill
      }
    },

    requestAfterthought({ state, commit, rootGetters }, params) {
      return sendAfterthoughtRequest(commit, rootGetters, params)
    },
    clientsHaveSupplies({ state, commit, dispatch, rootGetters }) {
      const accountClients = rootGetters['account/accountClients']
      let clients = {}
      return dispatch('getSupplies').then((supplies) => {
        for (let cl in accountClients) {
          for (let sup in supplies) {
            if (state.supplies[sup].clientOwnerCode === cl)
              clients[cl] = accountClients[cl]
          }
        }
        commit('setClientsWithSupplies', clients)

        return clients
      })
    },
    selfReading({ state, rootGetters }, params) {
      // invalidate the details of this supply, since we are modifying its data
      // (we want to get a fresh update from server)
      delete state.supplyDetail[params.supplyCode]

      return sendReading(rootGetters, params)
    },
    getTokenBidgely({ rootGetters }, data) {
      return retrieveTokenBidgely(rootGetters, data).then((resp) => resp)
    },
    getPaymentStatus({ state, commit, rootGetters }, params) {
      return retrievePaymentStatus(commit, rootGetters, params)
    },
    getCadastralData({ commit, rootGetters }, params) {
      return axiosACN
        .post(
          '/sorgeniadataclient/V2/retrieveCadastralData',
          {
            // codicePunto: 'PR5273610',
            codicePunto: params.supplyCode,
            codiceCliente: params.clientOwnerCode
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.status === 'OK') {
            commit('FETCH_CADASTRAL_DETAIL', {
              codicePunto: params.supplyCode,
              cadastral: res.data
            })
          } else {
            console.error('Impossible to retrieve supply Cadastral')
          }
          return res.data
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },
    sendCadastralData({ state, rootGetters }) {
      return axiosACN
        .post('/sorgeniadataclient/sendCadastralData ', state.cadastralData, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((resp) => {
          if (resp.data.status === 'OK') {
            return true
          } else {
            alert(resp.data.errorDescription)
            return false
          }
        })
    },
    async retrieveInfoPUNtuale({ commit, rootGetters }) {
      try {
        let response = await axiosACN.get('/commodity/retrieveInfoPUNtuale', {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        if (response.data.errorData) {
          let newResp = {
            errorData: response.data.errorData
          }
          commit('setInfoPUNtuale', newResp)
        } else {
          let TodayYmin = response.data.result.data[0].ordinateMin
          let TodayYmax = response.data.result.data[0].ordinateMax
          let TomorrowYmin = response.data.result.data[1].ordinateMin
          let TomorrowYmax = response.data.result.data[1].ordinateMax
          let xLabels = [
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24'
          ]
          let todayValues = []
          response.data.result.data[0].consumptions.forEach((element) => {
            todayValues.push(Number(element.consumption.toFixed(3)))
          })
          let tomorrowValues = []
          if (response.data.result.data[1].consumptions) {
            response.data.result.data[1].consumptions.forEach((element) => {
              tomorrowValues.push(Number(element.consumption.toFixed(3)))
            })
          }
          let newResp = {
            status: response.data.status,
            pageTitle: response.data.pageTitle,
            iconInfo: response.data.iconInfo,
            modalTitle: response.data.modalTitle,
            modalText: response.data.modalText,
            pageSubtitle: response.data.result.pageSubtitle,
            updateDate: response.data.result.updateDate,
            copyLegenda: response.data.result.copyLegenda,
            iconLegenda: response.data.result.iconLegenda,
            errorData: response.data.errorData,
            todayChart: {
              date: response.data.result.data[0].date,
              copyDate: response.data.result.data[0].copyDate,
              ordinateUnitMeasurement:
                response.data.result.data[0].ordinateUnitMeasurement,
              series: [
                {
                  name: 'name',
                  color: '#12256A',
                  data: todayValues
                }
              ],
              ymin: TodayYmin,
              ymax: TodayYmax,
              categories: xLabels
            },
            tomorrowChart: {
              hasChartData: tomorrowValues.length > 0,
              date: response.data.result.data[1].date,
              copyDate: response.data.result.data[1].copyDate,
              ordinateUnitMeasurement:
                response.data.result.data[0].ordinateUnitMeasurement,
              series: [
                {
                  name: 'name',
                  color: '#12256A',
                  data: tomorrowValues
                }
              ],
              ymin: TomorrowYmin,
              ymax: TomorrowYmax,
              categories: xLabels,
              errorCopy: response.data.result.data[1].errorCopy
            }
          }
          commit('setInfoPUNtuale', newResp)
        }
      } catch (err) {
        console.error('error in retrieveInfoPUNtuale', err)
        throw err
      }
    }
  }
}

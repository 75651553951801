import { initializeApp } from 'firebase/app'
import {
  getRemoteConfig,
  getValue,
  fetchAndActivate
} from 'firebase/remote-config'
import { ENV_CONFIGS } from '../configs'

const firebaseConfig = {
  apiKey: ENV_CONFIGS.FIREBASE.APIKEY,
  authDomain: ENV_CONFIGS.FIREBASE.AUTHDOMAIN,
  databaseURL: ENV_CONFIGS.FIREBASE.DATABASEURL,
  projectId: ENV_CONFIGS.FIREBASE.PROJECTID,
  storageBucket: ENV_CONFIGS.FIREBASE.STORAGEBUCKET,
  messagingSenderId: ENV_CONFIGS.FIREBASE.MESSAGINGSENDERID,
  appId: ENV_CONFIGS.FIREBASE.APPID,
  measurementId: ENV_CONFIGS.FIREBASE.MEASUREMENTID
}

let remoteConfig

async function initializeRemoteConfig() {
  try {
    const app = initializeApp(firebaseConfig)
    remoteConfig = getRemoteConfig(app)
    remoteConfig.settings.minimumFetchIntervalMillis = 0
    await fetchAndActivate(remoteConfig)
  } catch (error) {
    console.error('Errore nel fetch e attivazione di Remote Config:', error)
  }
}

async function fetchRemoteConfig(key) {
  try {
    const paramValue = getValue(remoteConfig, key)
    return {
      paramValue: paramValue._value
    }
  } catch (error) {
    console.error('Errore nel recupero di Remote Config:', error)
    return null
  }
}

export { initializeRemoteConfig, fetchRemoteConfig }
